import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { lambda_type, bool_type, record_type, unit_type, array_type, union_type, string_type, class_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { ClientUserQuery as ClientUserQuery_3, ClientUserQuery_get_Default, Account_$reflection, PagedDataResponse$1_$reflection, UserListItem_$reflection, ClientUserQuery_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { toInt32, equals, fromInt32, toInt64, compare } from "../fable_modules/fable-library.4.10.0/BigInt.js";
import { createObj, equals as equals_1 } from "../fable_modules/fable-library.4.10.0/Util.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { export$, searchUsersByDomain } from "../Api/UserApi.fs.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { formatInt } from "../Domain/Formatter.fs.js";
import { map as map_1, empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { UserTypeId_ClientAdministrator } from "../../CView.DTOs/Constants.fs.js";
import { RouterModule_encodeParts, RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { defaultOf } from "../fable_modules/FsToolkit.ErrorHandling.4.9.0/../../Pages/../fable_modules/fable-library.4.10.0/Util.js";
import { defaultArg } from "../fable_modules/fable-library.4.10.0/Option.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { defaultOf as defaultOf_1 } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(UserTypeId, ClientUserQuery, PreviousClientUserQuery, UsersDeferred, Accounts, ExportUsers) {
        super();
        this.UserTypeId = UserTypeId;
        this.ClientUserQuery = ClientUserQuery;
        this.PreviousClientUserQuery = PreviousClientUserQuery;
        this.UsersDeferred = UsersDeferred;
        this.Accounts = Accounts;
        this.ExportUsers = ExportUsers;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.ClientUserSearch.State", [], State, () => [["UserTypeId", class_type("System.Guid")], ["ClientUserQuery", ClientUserQuery_$reflection()], ["PreviousClientUserQuery", ClientUserQuery_$reflection()], ["UsersDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(UserListItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(UserListItem_$reflection())]], [["ErrorValue", string_type]]]))], ["Accounts", array_type(Account_$reflection())], ["ExportUsers", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetClientUsers", "UpdateClientUserQuery", "ExportClientUsers", "ClearSearch"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.ClientUserSearch.Msg", [], Msg, () => [[["Item1", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(UserListItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(UserListItem_$reflection())]], [["ErrorValue", string_type]]]))], ["Item2", bool_type]], [["Item1", lambda_type(ClientUserQuery_$reflection(), ClientUserQuery_$reflection())], ["Item2", bool_type]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], []]);
}

function hasPreviousPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            return result.fields[0].Page > 1;
        }
        else {
            return false;
        }
    }, state.UsersDeferred);
}

function hasNextPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            const pagedData = result.fields[0];
            return compare(toInt64(fromInt32(pagedData.Page * pagedData.PageSize)), pagedData.TotalCount) < 0;
        }
        else {
            return false;
        }
    }, state.UsersDeferred);
}

function isExportDisabled(state) {
    if (Deferred_exists((result) => {
        if (result.tag === 0) {
            return equals(result.fields[0].TotalCount, toInt64(fromInt32(0)));
        }
        else {
            return false;
        }
    }, state.UsersDeferred)) {
        return true;
    }
    else {
        return equals_1(state.UsersDeferred, new Deferred$1(1, []));
    }
}

export function init(userTypeId, globalAccountId, accounts) {
    let clientUserQuery;
    const bind$0040 = ClientUserQuery_get_Default();
    clientUserQuery = (new ClientUserQuery_3(globalAccountId, bind$0040.SearchCriteria, bind$0040.OnlyShowActive, bind$0040.Page, "DisplayName", bind$0040.OrderDirection));
    return [new State(userTypeId, clientUserQuery, clientUserQuery, new Deferred$1(0, []), accounts, new Deferred$1(0, [])), singleton((dispatch) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, []), false]));
    })];
}

export function update(msg, state) {
    let bind$0040, bind$0040_1;
    switch (msg.tag) {
        case 0:
            if (msg.fields[0].tag === 1) {
                return [new State(state.UserTypeId, (bind$0040 = state.ClientUserQuery, new ClientUserQuery_3(bind$0040.GlobalAccountId, bind$0040.SearchCriteria, state.PreviousClientUserQuery.OnlyShowActive, bind$0040.Page, bind$0040.OrderBy, bind$0040.OrderDirection)), msg.fields[1] ? state.PreviousClientUserQuery : state.ClientUserQuery, new Deferred$1(2, [msg.fields[0].fields[0]]), state.Accounts, state.ExportUsers), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Search Users", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none()];
            }
            else if (equals_1(state.UsersDeferred, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.UserTypeId, state.ClientUserQuery, state.PreviousClientUserQuery, new Deferred$1(1, []), state.Accounts, state.ExportUsers), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, searchUsersByDomain, msg.fields[1] ? state.PreviousClientUserQuery : (new ClientUserQuery_3(state.ClientUserQuery.GlobalAccountId, state.ClientUserQuery.SearchCriteria, state.PreviousClientUserQuery.OnlyShowActive, 1, state.PreviousClientUserQuery.OrderBy, state.PreviousClientUserQuery.OrderDirection)), (arg) => (new Msg(0, [new AsyncMsg$1(1, [arg]), msg.fields[1]])))];
            }
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.UserTypeId, state.ClientUserQuery, state.PreviousClientUserQuery, state.UsersDeferred, state.Accounts, new Deferred$1(2, [msg.fields[0].fields[0]])), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Export Client Users", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none()];
            }
            else if (equals_1(state.ExportUsers, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.UserTypeId, state.ClientUserQuery, state.PreviousClientUserQuery, state.UsersDeferred, state.Accounts, new Deferred$1(1, [])), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, export$, state.PreviousClientUserQuery, (arg_2) => (new Msg(2, [new AsyncMsg$1(1, [arg_2])])))];
            }
        case 3:
            return [new State(state.UserTypeId, (bind$0040_1 = ClientUserQuery_get_Default(), new ClientUserQuery_3(state.ClientUserQuery.GlobalAccountId, bind$0040_1.SearchCriteria, bind$0040_1.OnlyShowActive, bind$0040_1.Page, "DisplayName", bind$0040_1.OrderDirection)), state.PreviousClientUserQuery, state.UsersDeferred, state.Accounts, state.ExportUsers), singleton((dispatch) => {
                dispatch(new Msg(0, [new AsyncMsg$1(0, []), false]));
            })];
        default:
            return [new State(state.UserTypeId, msg.fields[1] ? state.ClientUserQuery : msg.fields[0](state.ClientUserQuery), msg.fields[1] ? msg.fields[0](state.PreviousClientUserQuery) : state.PreviousClientUserQuery, state.UsersDeferred, state.Accounts, state.ExportUsers), Cmd_none()];
    }
}

export function render(state, dispatch) {
    let elems_7, elems_6, elems, elems_1, matchValue, elems_5, children, elems_13, elems_12, elems_9, elems_8, value_112, elems_11, elems_10, elems_21, elems_23;
    const header = createElement("div", createObj(ofArray([["className", "box"], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-align-items-center"])], (elems_6 = [createElement("div", createObj(ofArray([["className", "column"], (elems = [createElement("span", {
        className: join(" ", ["has-text-weight-semibold", "has-text-link", "is-size-5"]),
        children: "Users",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_1 = [createElement("div", {
        className: "has-text-weight-semibold",
        children: (matchValue = state.UsersDeferred, (matchValue.tag === 1) ? "Searching..." : ((matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? ((compare(matchValue.fields[0].fields[0].TotalCount, toInt64(fromInt32(0))) > 0) ? (`${formatInt(~~toInt32(matchValue.fields[0].fields[0].TotalCount))} users found | showing page ${formatInt(matchValue.fields[0].fields[0].Page)} of ${formatInt(matchValue.fields[0].fields[0].NumberOfPages)}`) : "0 users found") : "") : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_5 = [(children = toList(delay(() => {
        let elems_2;
        return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["disabled", !hasPreviousPage(state)], ["type", "button"], ["onClick", (_arg) => {
            if (hasPreviousPage(state)) {
                dispatch(new Msg(1, [(query) => (new ClientUserQuery_3(query.GlobalAccountId, query.SearchCriteria, query.OnlyShowActive, state.PreviousClientUserQuery.Page - 1, query.OrderBy, query.OrderDirection)), true]));
                dispatch(new Msg(0, [new AsyncMsg$1(0, []), true]));
            }
        }], (elems_2 = [createElement("span", {
            className: join(" ", ["material-icons", "is-small"]),
            children: "navigate_before",
        }), createElement("span", {
            className: "is-sr-only",
            children: "Previous",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
            let elems_3;
            return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state)], ["onClick", (_arg_1) => {
                if (hasNextPage(state)) {
                    dispatch(new Msg(1, [(query_1) => (new ClientUserQuery_3(query_1.GlobalAccountId, query_1.SearchCriteria, query_1.OnlyShowActive, state.PreviousClientUserQuery.Page + 1, query_1.OrderBy, query_1.OrderDirection)), true]));
                    dispatch(new Msg(0, [new AsyncMsg$1(0, []), true]));
                }
            }], (elems_3 = [createElement("span", {
                className: join(" ", ["material-icons", "is-small"]),
                children: "navigate_next",
            }), createElement("span", {
                className: "is-sr-only",
                children: "Next",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => append(singleton_1(createElement("button", {
                className: join(" ", ["button", "is-small", "ml-2"]),
                type: "button",
                children: "Export",
                disabled: equals_1(state.ExportUsers, new Deferred$1(1, [])) ? true : isExportDisabled(state),
                onClick: (_arg_2) => {
                    dispatch(new Msg(2, [new AsyncMsg$1(0, [])]));
                },
            })), delay(() => {
                let elems_4;
                return (state.UserTypeId === UserTypeId_ClientAdministrator) ? singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["onClick", (_arg_3) => {
                    RouterModule_nav(singleton("new-user"), 1, 1);
                }], (elems_4 = [createElement("span", {
                    className: join(" ", ["material-icons", "is-small"]),
                    children: "add",
                }), createElement("span", {
                    className: join(" ", ["is-sr-only"]),
                    children: "New User",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))]), ["disabled", state.ClientUserQuery.GlobalAccountId === "00000000-0000-0000-0000-000000000000"]])))) : empty();
            }))));
        }));
    })), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
    const searchForm = createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_13 = [createElement("form", createObj(ofArray([["onSubmit", (e) => {
        e.preventDefault();
        if (state.Accounts.length === 0) {
        }
        else {
            dispatch(new Msg(0, [new AsyncMsg$1(0, []), false]));
        }
    }], ["className", "box"], ["style", {
        minWidth: 300 + "px",
    }], (elems_12 = [createElement("div", createObj(ofArray([["className", "block"], (elems_9 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "is-align-items-center", "mb-1"])], (elems_8 = [createElement("label", {
        className: "has-text-weight-semibold",
        for: "userName",
        children: "Search",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small", "is-fullwidth"])], ["id", "userName"], ["name", "userName"], ["type", "text"], ["maxLength", 256], ["placeholder", "Search..."], ["onChange", (ev) => {
        const value_106 = ev.target.value;
        dispatch(new Msg(1, [(query_2) => {
            let value_108;
            return new ClientUserQuery_3(query_2.GlobalAccountId, (value_108 = value_106, (value_108 === defaultOf()) ? void 0 : value_108), query_2.OnlyShowActive, query_2.Page, query_2.OrderBy, query_2.OrderDirection);
        }, false]));
    }], ["onKeyPress", (keyPress) => {
        if (keyPress.key === "Enter") {
            dispatch(new Msg(0, [new AsyncMsg$1(0, []), false]));
        }
    }], (value_112 = defaultArg(state.ClientUserQuery.SearchCriteria, ""), ["ref", (e_1) => {
        if (!(e_1 == null) && !equals_1(e_1.value, value_112)) {
            e_1.value = value_112;
        }
    }])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "mb-2"])], (elems_10 = [createElement("input", {
        className: join(" ", ["is-clickable", "is-small"]),
        id: "IsActive",
        name: "IsActive",
        type: "checkbox",
        checked: state.PreviousClientUserQuery.OnlyShowActive,
        onChange: (ev_1) => {
            const value_127 = ev_1.target.checked;
            dispatch(new Msg(1, [(query_3) => (new ClientUserQuery_3(query_3.GlobalAccountId, query_3.SearchCriteria, value_127, 1, query_3.OrderBy, query_3.OrderDirection)), true]));
            dispatch(new Msg(0, [new AsyncMsg$1(0, []), true]));
        },
    }), createElement("label", {
        className: join(" ", ["is-clickable", "ml-2", "has-text-weight-semibold"]),
        for: "IsActive",
        children: "Active Only",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("hr", {
        className: "my-4",
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-primary"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals_1(state.UsersDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty())))))))))))))),
        type: "submit",
        children: "Search",
        disabled: state.Accounts.length === 0,
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals_1(state.UsersDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty())))))))))))),
        type: "button",
        onClick: (_arg_4) => {
            dispatch(new Msg(3, []));
        },
        children: "Clear",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])));
    const searchTable = createElement("div", createObj(ofArray([["className", "column"], (elems_21 = toList(delay(() => {
        let elems_20, elems_19, elems_18, children_2, elems_14, elems_17;
        const matchValue_2 = state.UsersDeferred;
        let matchResult, pagedData_3;
        switch (matchValue_2.tag) {
            case 1: {
                matchResult = 0;
                break;
            }
            case 2: {
                if (matchValue_2.fields[0].tag === 0) {
                    if (compare(matchValue_2.fields[0].fields[0].TotalCount, toInt64(fromInt32(0))) > 0) {
                        matchResult = 1;
                        pagedData_3 = matchValue_2.fields[0].fields[0];
                    }
                    else {
                        matchResult = 2;
                    }
                }
                else {
                    matchResult = 2;
                }
                break;
            }
            default:
                matchResult = 2;
        }
        switch (matchResult) {
            case 0:
                return singleton_1(render_1());
            case 1:
                return singleton_1(createElement("div", createObj(ofArray([["className", "box"], (elems_20 = [createElement("div", createObj(ofArray([["className", "table-container"], (elems_19 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_18 = [(children_2 = singleton(createElement("tr", createObj(singleton((elems_14 = [createElement("th", {
                    className: join(" ", ["p-2", "has-text-link", "is-underlined", "is-clickable"]),
                    scope: "col",
                    children: "Name",
                    onClick: (_arg_5) => {
                        dispatch(new Msg(1, [(query_4) => (new ClientUserQuery_3(query_4.GlobalAccountId, query_4.SearchCriteria, query_4.OnlyShowActive, query_4.Page, "DisplayName", (state.PreviousClientUserQuery.OrderDirection === "ASC") ? "DESC" : "ASC")), true]));
                        dispatch(new Msg(0, [new AsyncMsg$1(0, []), true]));
                    },
                }), createElement("th", {
                    className: join(" ", ["p-2", "has-text-link", "is-underlined", "is-clickable"]),
                    scope: "col",
                    children: "Email",
                    onClick: (_arg_6) => {
                        dispatch(new Msg(1, [(query_5) => (new ClientUserQuery_3(query_5.GlobalAccountId, query_5.SearchCriteria, query_5.OnlyShowActive, query_5.Page, "Email", (state.PreviousClientUserQuery.OrderDirection === "ASC") ? "DESC" : "ASC")), true]));
                        dispatch(new Msg(0, [new AsyncMsg$1(0, []), true]));
                    },
                }), createElement("th", {
                    className: join(" ", ["p-2", "has-text-link"]),
                    scope: "col",
                    children: "Active",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))]))))), createElement("thead", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                })), createElement("tbody", createObj(singleton((elems_17 = toList(delay(() => map_1((user) => {
                    let elems_15, elems_16;
                    const children_4 = ofArray([createElement("td", createObj(ofArray([["className", "p-2"], (elems_15 = [createElement("a", {
                        className: join(" ", ["is-link", "is-underlined"]),
                        href: RouterModule_encodeParts(ofArray(["/user-details", user.UserId]), 1),
                        children: user.DisplayName,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))), createElement("td", {
                        className: "p-2",
                        children: user.Email,
                    }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_16 = [createElement("span", {
                        className: join(" ", toList(delay(() => append(singleton_1("tag"), delay(() => (user.IsActive ? singleton_1("is-success") : empty())))))),
                        children: user.IsActive ? "Yes" : "No",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))]);
                    return createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    });
                }, pagedData_3.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]))));
            default:
                return singleton_1(defaultOf_1());
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])])));
    const mainContent = createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray([searchForm, searchTable]),
    });
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_23 = toList(delay(() => {
        let children_6;
        return (state.Accounts.length === 0) ? singleton_1((children_6 = singleton(createElement("h4", {
            className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-5"]),
            children: "Please select a client account",
        })), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        }))) : append(singleton_1(header), delay(() => singleton_1(mainContent)));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])));
}

